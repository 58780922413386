<script setup>
import MainFooter from "@/components/partial/MainFooter.vue";
import { ref, defineProps, computed, onMounted } from 'vue';
import Shared from "@/common/shared";
import { Regions, Dictionary, Regulations, MySelf } from "@/common/api.service";
import { useRouter } from "vue-router";


const router = useRouter();

const props = defineProps({
    selectedRegion: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    }
});

const regions = ref([]);
const statutes = ref([]);
const typesNpa = ref([]);
const regionSelected = ref(props.selectedRegion);
const regulationId = ref(props.title);
const npaData = ref({});
const isRegionalFundEmployee = ref(false);
const permissions = ref([]);

await Regulations.getNpa(regionSelected.value, regulationId.value)
    .then(({ data }) => {
        npaData.value = data;
    })
    .catch(async () => {
    });

    await Regions.permissions()
  .then(async ({ data }) => {
    permissions.value = data
  }).catch(async () => {
    await router.push({ name: 'Error' })
  })

function permission(permission) {
  return permissions.value.find((p) => p === permission)
}

await Regions.list()
    .then(({ data }) => {
        regions.value = data;
    })
    .catch(() => {
    });

await Dictionary.regulationsStatuses()
    .then(({ data }) => {
        statutes.value = data;
    })
    .catch(() => {
    });

await Dictionary.regulationsTypes()
    .then(({ data }) => {
        typesNpa.value = data;
    })
    .catch(() => {
    });

const regionList = computed(() => {
    let r = [];
    if (Array.isArray(regions.value)) {
        regions.value.forEach(function (region) {
            r.push({ value: region.id, label: region.title });
        });
    }
    return r;
});

const statutesList = computed(() => {
    let r = [];
    if (Array.isArray(statutes.value)) {
        statutes.value.forEach(function (status) {
            r.push({ value: status.value, label: status.title });
        });
    }
    return r;
});

const typesNpaList = computed(() => {
    let r = [];
    if (Array.isArray(typesNpa.value)) {
        typesNpa.value.forEach(function (typeNpa) {
            r.push({ value: typeNpa.value, label: typeNpa.title });
        });
    }
    return r;
});

async function saveEditNpa() {
    Regulations.updateNpa(regionSelected.value, regulationId.value, npaData.value)
        .then(async () => {
            if (regionSelected.value) {
                await router.push({ name: 'ListNpa', params: { region: regionSelected.value } });
            }
        })
        .catch(() => {
        });
}

async function showDelDocument() {
    Shared.showModal('#del-document')
}

async function closeDelDocument() {
    Shared.closeModal('#del-document')
}

async function showDelModal() {
    Shared.showModal('#close-document')
}

async function closeDelModal() {
    Shared.closeModal('#close-document');
    await router.push({ name: 'ListNpa', params: { regionSelected } });
}

async function closeEditNpa() {
    if (regionSelected.value) {
        router.push({ name: 'ListNpa', params: { region: regionSelected.value } });
    }
}

async function removeNpa() {
    Regulations.removeNpa(regionSelected.value, regulationId.value, npaData.value)
        .then(async () => {
            await closeDelDocument();
            await showDelModal();
        })
        .catch(() => {
        });
}

onMounted(async () => {
    await MySelf.card().then(async ({ data }) => {
        isRegionalFundEmployee.value = data.isRegionalFundEmployee;
    });
});
</script>


<template>
    <div class="wrapper">
        <MainHeader></MainHeader>
        <main class="page page--js">
            <div class="breadcrumbs-container">
                <div class="container">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'ListNpa', params: { region: regionSelected } }">
                                    Реестр НПА по СПИК
                                </router-link>
                            </li>
                            <li>{{ npaData.title }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container">
                    <div class="content__wrapper">
                        <div class="content__contract">
                            <div class="content__title content__title--contract">
                                <h1 class="title title-h1--contract">{{ npaData.title }}</h1>
                            </div>
                            <form action="#" class="contract">
                                <div class="contract__row">
                                    <div class="contract__item">
                                        <label for="region" class="title-field">Регион*
                                            <Multiselect class="field field--select margin-0" name="region"
                                                v-model="regionSelected" mode="single" :options="regionList"
                                                :show-no-results="false" :searchable="true"
                                                placeholder="Наименование региона" :no-options-text="'Укажите регион'"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }" disabled />
                                        </label>
                                        <label for="type-NPA" class="title-field">Тип*
                                            <Multiselect class="field field--select margin-0" name="type-NPA"
                                                v-model="npaData.regulationsType" mode="single" :options="typesNpaList"
                                                :show-no-results="false" :searchable="true"
                                                placeholder="Выберите из списка" :no-options-text="'Укажите тип'"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee" />
                                        </label>
                                        <label for="status-NPA" class="title-field">Статус*
                                            <Multiselect class="field field--select margin-0" name="status-NPA"
                                                v-model="npaData.regulationsStatus" mode="single"
                                                :options="statutesList" :show-no-results="false" :searchable="true"
                                                placeholder="Статус НПА" :no-options-text="'Укажите статус'"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee" />
                                        </label>
                                        <label for="NPA-name" class="title-field">Название*
                                            <textarea class="field field--textarea" type="text" name="NPA-name"
                                                v-model="npaData.title" placeholder="Введите название НПА"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee"></textarea>
                                        </label>
                                        <label for="tax-rate" class="title-field">Размер налоговой ставки для
                                            федеральных СПИК, %
                                            <input class="field" type="text" name="tax-rate"
                                                v-model="npaData.federalRate"
                                                placeholder="Введите размер налоговой ставки в процентах"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee">
                                        </label>
                                        <label for="tax-rate-regional" class="title-field">Размер налоговой ставки для
                                            региональных СПИК, %
                                            <input class="field" type="text" name="tax-rate-regional"
                                                v-model="npaData.regionalRate"
                                                placeholder="Введите размер налоговой ставки в процентах"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee">
                                        </label>
                                    </div>
                                    <div class="contract__item">
                                        <label for="transfer-date" class="title-field">Дата проверки*
                                            <VueDatePicker name="transfer-date" placeholder="00.00.0000"
                                                model-type="yyyy-MM-dd" class="field field--calendar"
                                                v-model="npaData.verificationDate"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee" />
                                        </label>
                                        <label for="comment-NPA" class="title-field">Комментарий
                                            <textarea class="field field--textarea" type="text" name="comment-NPA"
                                                v-model="npaData.comment" placeholder="Введите комментарий"
                                                :class="{ 'field--viewing': isRegionalFundEmployee }"
                                                :disabled="isRegionalFundEmployee"></textarea>
                                        </label>
                                        <div class="entry-info">
                                            <div class="entry-info__item">
                                                Дата создания записи:<span>{{ new
                                                    Date(npaData.createdAt).toLocaleString()
                                                    }}</span>
                                            </div>
                                            <div class="entry-info__item">
                                                Создание записи, кем:<span>{{ npaData.creator.firstName }} {{
                                                    npaData.creator.lastName }}</span>
                                            </div>
                                            <div class="entry-info__item">
                                                Дата изменения записи:<span>{{ new
                                                    Date(npaData.modifiedAt).toLocaleString()
                                                    }}</span>
                                            </div>
                                            <div class="entry-info__item">
                                                Изменение записи, кем:<span>{{ npaData.modifier.firstName }} {{
                                                    npaData.modifier.lastName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-block buttons-block--right">
                                    <button type="button" class="button button--delete modal-open--js"
                                        data-modal="del-document" @click="showDelDocument"
                                        :class="{ 'hidden': isRegionalFundEmployee }" v-if="permission('/regions/npa:delete')">Удалить</button>
                                    <button type="reset" class="button button--clear"
                                        :class="{ 'hidden': isRegionalFundEmployee }" v-if="permission('/regions/npa:update')">Отмена изменений</button>
                                    <button type="button" class="button" @click="saveEditNpa"
                                        :class="{ 'hidden': isRegionalFundEmployee }" v-if="permission('/regions/npa:update')">сохранить</button>
                                    <button type="button" class="button button--clear"
                                        @click="closeEditNpa" v-if="permission('/regions/npa:read')">Закрыть</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-overlay" id="del-document">
                <div class="popup">
                    <div class="modal-title">Удаление документа</div>
                    <div class="modal-body">Вы действительно хотите удалить НПА?</div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="button" class="button button--clear close-modal--js"
                                @click="closeDelDocument">Закрыть</button>
                            <button type="submit" class="button button--delete sub-modal-open--js"
                                data-modal="close-document" @click="removeNpa">Удалить</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-overlay" id="close-document">
                <div class="popup">
                    <div class="modal-title">Документ удален</div>
                    <div class="modal-body"></div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="button" class="button close-modal--js" @click="closeDelModal">Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <MainFooter></MainFooter>
    </div>
</template>

<style scoped></style>