export const API_URL = "https://test-regions.frprf.ru/api/";
export const AUTH_URL = "https://test-id.frprf.ru/connect/authorize";
export const AUTH_TOKEN = "https://test-id.frprf.ru/connect/token";
export const AUTH_LOGOUT = "https://test-id.frprf.ru/connect/logout";
export const AUTH_PROFILE = "https://test-id.frprf.ru/connect/userinfo";
export const GATEWAY_URL = "https://test-gateway.frprf.ru/";
export const CLIENT_ID = "FRP-SERVICES-REGIONS-WEBAPP"
export const CLIENT_SECRET = "7002C48B-E98E-4610-91B3-131DBA545CD8"
export const CLIENT_URI = "https://regions-frp.pharus-dev.ru/"
export const IS_DEV_MODE = false;
