<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import { computed, ref } from 'vue'
import { Dictionary, Funds } from '@/common/api.service'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const item = ref({})
const supportedIndustries = ref([])
const targetedUses = ref([])
const fund = ref({})

await Funds.ownPrograms(route.params.fund)
    .then(({ data }) => {
      item.value = data.find(i => i.id === route.params.own)
      let s = []
      for (let i = 0; i < item.value.supportedIndustries.length; i++) {
        s.push(item.value.supportedIndustries[i].value)
      }
      item.value.supportedIndustries = s
      let u = []
      for (let i = 0; i < item.value.targetedUses.length; i++) {
        u.push(item.value.targetedUses[i].value)
      }
      item.value.targetedUses = u
    }).catch(() => {
    })

await Funds.item(route.params.fund)
    .then(({ data }) => {
      fund.value = data
    }).catch(() => {
    })

await Dictionary.supportedIndustries()
    .then(({ data }) => {
      supportedIndustries.value = data
    }).catch(() => {
    })

await Dictionary.targetedUses()
    .then(({ data }) => {
      targetedUses.value = data
    }).catch(() => {
    })

const supportedIndustriesList = computed(() => {
  let r = []
  supportedIndustries.value.forEach(function (f) {
    r.push({ value: f.value, label: `${f.title}` })
  })

  return r
})

const targetedUsesList = computed(() => {
  let r = []
  targetedUses.value.forEach(function (f) {
    r.push({ value: f.value, label: `${f.title}` })
  })

  return r
})

async function saveOwnProgram () {
  await Funds.updateOwnPrograms(route.params.fund, item.value.id, item.value).then(async () => {
    await router.push({ name: 'CardRegions', params: { fund: route.params.fund } })
  })
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Список регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'CardRegions', params: { fund: fund.id}}">
                  {{ fund.region.title }}
                </router-link>
              </li>
              <li>{{ item.title }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract">Добавление самостоятельной программы</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <label for="independent-program-name" class="title-field">Название
                      самостоятельной программы*
                      <input class="field" type="text" v-model="item.title"
                             placeholder="Введите название программы">
                    </label>
                    <label for="description-program" class="title-field">Описание программы*
                      <textarea class="field field--textarea" type="text"
                                v-model="item.description"
                                placeholder="Введите описание программы"></textarea>
                    </label>
                    <label for="industry" class="title-field">Поддерживаемые отрасли*
                      <Multiselect
                          placeholder=""
                          class="field field--select" style="height: auto"
                          v-model="item.supportedIndustries"
                          mode="tags"
                          :searchable="true"
                          :aria-expanded="true"
                          :options="supportedIndustriesList"
                      />
                    </label>
                    <label for="direction" class="title-field">Направление целевого использования*
                      <Multiselect
                          placeholder=""
                          class="field field--select" style="height: auto"
                          v-model="item.targetedUses"
                          mode="tags"
                          :searchable="true"
                          :aria-expanded="true"
                          :options="targetedUsesList"
                      />
                    </label>
                    <label for="gisp-card-link" class="title-field">Ссылка на карточку ГИСП
                      <input class="field" type="text" v-model="item.gispCardUrl"
                             placeholder="Введите URL адрес">
                    </label>
                    <label for="rfrp-card-link" class="title-field">Введите URL адрес
                      <input class="field" type="text" v-model="item.rfrpCardUrl"
                             placeholder="Введите URL адрес">
                    </label>
                  </div>
                  <div class="contract__item">
                    <label for="loan-term" class="title-field">Срок займа, диапазон лет*
                      <input class="field" type="text" v-model="item.loanDuration"
                             placeholder="Введите диапазон срока займа">
                    </label>
                    <div class="interval-fields">
                      <label for="loan-term-min" class="title-field">Сумма займа, млн руб.*
                        <input class="field" type="text" v-model="item.loanSumMin"
                               placeholder="Введите минимальную сумму">
                      </label>
                      <span></span>
                      <label for="loan-term-max" class="title-field">
                        <input class="field" type="text" v-model="item.loanSumMax"
                               placeholder="Введите максимальную сумму">
                      </label>
                    </div>
                    <label for="loan-rate" class="title-field">Ставка, %*
                      <input class="field" type="text" v-model="item.rate"
                             placeholder="Введите ставку в процентах">
                    </label>
                    <label for="loan-co-financing" class="title-field">Софинансирование, %*
                      <input class="field" type="text" v-model="item.cofinanceRate"
                             placeholder="Введите условия софинансирования">
                    </label>
                    <label for="total-budget" class="title-field">Общий бюджет заявки, млн руб.
                      <input class="field" type="text" v-model="item.commonBudget"
                             placeholder="Введите общий бюджет заявки">
                    </label>
                  </div>
                </div>
                <div class="contract__row">
                  <div class="entry-info entry-info--width50">
                    <div class="entry-info__item">Дата создания записи:<span>01.04.2023
                                                00:00:00</span></div>
                    <div class="entry-info__item">Создание записи, кем:<span>Иван Иванов </span>
                    </div>
                    <div class="entry-info__item">Дата изменения записи:<span>12.04.2023
                                                00:00:00</span></div>
                    <div class="entry-info__item">Изменение записи, кем:<span>Иван Иванов
                                            </span></div>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <button type="reset" class="button button--clear">Отмена изменений</button>
                  <button type="button" class="button" @click="saveOwnProgram">сохранить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup popup-del">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить самостоятельную программу?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear close-modal--js">Закрыть</button>
              <button type="button" class="button button--delete sub-modal-open--js"
                      data-modal="close-document">Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Запись удалена</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button close-modal--js">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>