import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import UserService from "@/common/user.service";

import {
    LOGIN,
    LOGIN_ECP,
    LOGOUT,
    REGISTER,
    CHECK_AUTH,
    OAUTH,
    UPDATE_USER,
    UPDATE_USER_ROLE,
    GET_USER_ROLE,
    REFRESH_TOKEN
} from "./actions.type";
import {
    SET_AUTH,
    PURGE_AUTH,
    SET_ERROR,
    SET_ROLE,
    SET_FULL_ROLE,
    SET_TOKEN
} from "./mutations.type";
import {AUTH_PROFILE, AUTH_TOKEN, CLIENT_ID, CLIENT_SECRET, CLIENT_URI} from "@/common/config";
import axios from "axios";

const authStore = {
    state() {
        return {
            errors: null,
            user: {},
            isAuthenticated: !!JwtService.getToken()
        }
    },
    getters: {
        currentUser(state, getters) {
            if (UserService.getUser() == null) {
                return getters.user;
            }
            return UserService.getUser();
        },
        isAuthenticated(state) {
            return JwtService.getToken() !== "undefined" && state.isAuthenticated;
        }
    },
    actions: {
        [LOGIN](context, credentials) {
            return new Promise(resolve => {
                ApiService.post("auth/getAccessToken", credentials)
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        },
        [REFRESH_TOKEN](context) {
            return new Promise(resolve => {
                ApiService.get("auth/getRefreshToken")
                    .then(({data}) => {
                        context.commit(SET_TOKEN, data);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        },
        [LOGIN_ECP](context, credentials) {
            return new Promise(resolve => {
                ApiService.post("auth/getAccessToken/", credentials)
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        },
        async [LOGOUT](context) {
            await context.commit(PURGE_AUTH);
        },
        [REGISTER](context, credentials) {
            return new Promise((resolve, reject) => {
                ApiService.post("users", {user: credentials})
                    .then(({data}) => {
                        context.commit(SET_AUTH, data.user);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                        reject(response);
                    });
            });
        },
        [CHECK_AUTH](context) {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("user")
                    .then(({data}) => {
                        context.commit(SET_AUTH, data.user);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            } else {
                context.commit(PURGE_AUTH);
            }
        },
        async [OAUTH](context, credentials) {
            await ApiService.postEncodedURI(AUTH_TOKEN, {
                grant_type: "authorization_code",
                code: credentials,
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                redirect_uri: CLIENT_URI,
                code_verifier: window.sessionStorage.getItem("code_verifier")
            })
                .then(async ({data}) => {
                    ApiService.setHeader();
                    let dt = data
                    await axios.get(AUTH_PROFILE, {
                        headers: {
                            Authorization: "Bearer " + dt.access_token,
                        }
                    }).then(async ({data}) => {
                        dt.profile = data.profile
                        await context.commit(SET_AUTH, dt);
                    }).catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        },
        [UPDATE_USER_ROLE](context, roleId) {
            return new Promise(resolve => {
                ApiService.post("auth/refreshToken/changeGroup", {
                    GROUP_ID: roleId
                })
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        },
        [GET_USER_ROLE](context) {
            return new Promise(resolve => {
                ApiService.get("users/getActiveGroup")
                    .then(({data}) => {
                        context.commit(SET_FULL_ROLE, data);
                        resolve(data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        },
        [UPDATE_USER](context, payload) {
            const {email, username, password, image, bio} = payload;
            const user = {
                email,
                username,
                bio,
                image
            };
            if (password) {
                user.password = password;
            }

            return ApiService.put("user", user).then(({data}) => {
                context.commit(SET_AUTH, data.user);
                return data;
            });
        }
    },
    mutations: {
        [SET_ERROR](state, error) {
            state.errors = error;
        },
        [SET_AUTH](state, data) {
            if (typeof data.access_token === "undefined") {
                return;
            }
            JwtService.saveToken(data.access_token);
            ApiService.setHeader();
            state.isAuthenticated = true;
            state.user = data;
            UserService.saveUser(data);
            state.errors = {};
        },
        [SET_TOKEN](state, data) {
            if (typeof data.jwt === "undefined") {
                return;
            }
            JwtService.saveToken(data.jwt);
            state.errors = {};
        },
        [SET_ROLE](state, role) {
            let user = UserService.getUser();
            user.ACTIVE_ROLE = role;
            UserService.saveUser(user);
        },
        [SET_FULL_ROLE](state, data) {
            let user = UserService.getUser();
            user.ACTIVE_ROLE = data.activeGroupId;
            user.ACTIVE_ROLE_CODE = data.activeGroupCode;
            user.TYPE_USER = data.typeUser;
            user.IS_EXPERT_USER = data.isExpertUser;
            user.REDIRECT = data.redirect;
            user.IS_EXPERT_DIRETOR = user.EXPERT_DIRETOR_LIST_GROUP_ID.includes(
                user.ACTIVE_ROLE
            )
                ? 1
                : 0;
            UserService.saveUser(user);
        },
        async [PURGE_AUTH](state) {
            JwtService.destroyToken();
            UserService.destroyUser();
            state.isAuthenticated = false;
            state.user = {};
            state.errors = {};
        }
    }
}

export default authStore;
