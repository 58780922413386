<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import { Regions, Funds } from '@/common/api.service'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const list = ref({
  agreements: []
})
const fl = ref({
  region: '', organizationTitle: '', district: ''
})
const s = ref({
  region: '', organizationTitle: '', district: ''
})
const fund = ref({})
const projectManagers = ref([])
const districts = ref([])

async function fetchList () {
  let filter = {}
  if (route.params.id) {
    await Funds.item(route.params.id)
        .then(({ data }) => {
          fund.value = data
        }).catch(() => {
        })
    filter.regionId = fund.value.region.id
  }

  await Funds.agreementRegistry(filter)
      .then(({ data }) => {
        list.value = data

      }).catch(async () => {
        await router.push({ name: 'Error' })
      })
}

const filteredList = computed(() => {
  let r = list.value.agreements
  if (fl.value.region !== '') {
    r = r.filter(v => v.regionId === fl.value.region)
  }
  if (fl.value.organizationTitle !== '') {
    r = r.filter(v => v.organizationTitle.toLowerCase().includes(fl.value.organizationTitle.toLowerCase()))
  }
  if (fl.value.district !== '') {
    r = r.filter(v => v.district.title === fl.value.district)
  }

  return r
})

const regionList = computed(() => {
  let r = []
  list.value.agreements.forEach(function (f) {
    if (!r.find(v => v.value === f.regionId)) {
      r.push({ value: f.regionId, label: `${f.regionTitle}` })
    }
  })
  return r
})

await Regions.projectManagers()
    .then(({ data }) => {
      data.forEach(function (f) {
        projectManagers.value.push({ value: f.shortName, label: `${f.shortName}` })
      })
    }).catch(() => {
    })

await Regions.districts()
    .then(({ data }) => {
      data.forEach(function (f) {
        districts.value.push({ value: f.title, label: `${f.title}` })
      })
    }).catch(() => {
    })

function appendFilter () {
  let f = JSON.stringify(s.value)
  fl.value = JSON.parse(f)
}

function clearFilter () {
  fl.value = {
    region: '', organizationTitle: '', district: ''
  }
  s.value = {
    region: '', organizationTitle: '', district: ''
  }
}

await fetchList()
</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li><a href="/regions">Модуль регионов</a></li>
              <li>Реестр договоров займа по самостоятельным программам
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__title">
              <h1 class="title title-h1">Реестр договоров займа по самостоятельным программам
              </h1>
              <router-link :to="{ name: 'AddAgreement', params: {id: route.params.id} }" class="button">
                Добавить документ
              </router-link>
            </div>
            <form action="#" class="region-list__form">
              <div class="region-list__form-body">
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.region" mode="single"
                             :options="regionList" :show-no-results="false" :searchable="true" placeholder="Регион ФРП"
                             :no-options-text="'Укажите регион'"/>
                <input class="field field--m-width-265 margin-0" type="text" name="project-revenue"
                       v-model="s.organizationTitle"
                       placeholder="Название организации">
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.district" mode="single"
                             :options="districts" :show-no-results="false" :searchable="true" placeholder="Округ"
                             :no-options-text="'Укажите округ'"/>
                <div class="region-list__counter hidden">Всего элементов: 4</div>
              </div>
              <div class="buttons-block">
                <button type="reset" class="button button--clear" @click="clearFilter">Очистить фильтры</button>
                <button type="button" class="button" @click="appendFilter">Поиск</button>
              </div>
            </form>
            <div class="table-responsive">
              <table class="table region-list">
                <thead>
                <tr>
                  <th>Название региона</th>
                  <th>Название РФРП</th>
                  <th>Дата договора займа</th>
                  <th>Сумма займа,млн руб</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in filteredList" :key="item.id">
                  <td><a href="#" class="link link--big"></a>
                    <router-link :to="{ name: 'EditAgreement', params: {id: route.params.id, agreement: item.id } }"
                                 class="link link--big">
                      {{ item.regionTitle }}
                    </router-link>
                  </td>
                  <td>{{ item.organizationTitle }}
                  </td>
                  <td>{{ item.agreementDate }}</td>
                  <td>{{ item.loanSum }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>