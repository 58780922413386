<script setup>

import moment from 'moment'
import {
  OAUTH
} from '@/store/actions.type'
import { useRouter, useRoute } from 'vue-router'
import JwtService from '@/common/jwt.service'
import jwtDecode from 'jwt-decode'
import { useStore } from 'vuex'
import { onMounted } from 'vue'

import { AUTH_URL, CLIENT_ID, CLIENT_URI } from '@/common/config'

async function generateCodeChallenge (codeVerifier) {
  let digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

function generateRandomString (length) {
  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

const router = useRouter()
const route = useRoute()
const store = useStore()

onMounted(async () => {
  await router.isReady()

  if (typeof route.query.code != 'undefined') {
    await store.dispatch(OAUTH, route.query.code).then(async () => {
      await router.push({ name: 'ListRegions' })
    })
  } else if (!store.getters.isAuthenticated && route.name !== 'Auth') {
    //if (IS_DEV_MODE) {
    //  await router.push({
    //    name: "Auth",
    //    query: {redirect: window.location.pathname + window.location.search}
    //  });
    //} else {
    let codeVerifier = generateRandomString(5)
    window.sessionStorage.setItem('code_verifier', codeVerifier)

    const codeChallenge = await generateCodeChallenge(codeVerifier)
    let args = new URLSearchParams({
      response_type: 'code',
      client_id: CLIENT_ID,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      redirect_uri: CLIENT_URI,
      state: Math.random(),
    })
    window.location = AUTH_URL + '?' + args
  } else if (store.getters.isAuthenticated && route.name === 'Auth') {
    await store.dispatch(OAUTH, route.query.access_token).then(async () => {
      await router.push({ name: 'ListRegions' })
    })
  }

  if (typeof store.getters.currentUser !== 'undefined') {
    //this.newActiveGroup = store.getters.currentUser.ACTIVE_ROLE;
    if (typeof store.getters.currentUser.GROUP_ID !== 'undefined' && store.getters.currentUser.GROUP_ID.length > 1) {
      this.roleChangeAvail = true
    }
    if (!route.query.access_token) {
      let exp = jwtDecode(JwtService.getToken())
      let expDate = new Date()
      expDate.setTime(exp.exp * 1000)
      let date = new Date()
      if (expDate.getTime() < moment(date)
          .toDate()
          .getTime()) {
        //alert("Будет осуществлен выход из системы по причине не активности!");
        this.logout()
      }
    }
  }
})
</script>
<template>
  <Suspense>
    <router-view/>
  </Suspense>
</template>



