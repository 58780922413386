<script setup async>

</script>
<template>
  <footer class="footer footer--js">
    <div class="footer__wrapper">
      <a href="#" class="logo"></a>
    </div>
  </footer>
</template>
